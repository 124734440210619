import React, { Component, useRef } from "react";
import { ReactComponent as FooterCompleto } from "../../images/footer-completo.svg";
import { ReactComponent as FooterCompletoMobile } from "../../images/footer-completo-mobile.svg";

export default function Footer() {
  const windowWidth = useRef(window.innerWidth);

  if (windowWidth.current >= 900) {
    return (
      <div>
        <FooterCompleto />
      </div>
    );
  }
  if (windowWidth.current <= 900) {
    return (
      <div>
        <FooterCompletoMobile />
      </div>
    );
  }
}
