import React, { useRef } from "react";
import PropTypes from "prop-types";
import DownloadButton from "../DownloadButton";
import { CajaGrilla, CajaGrillaMobile } from "../CajaGrilla";

import "./styles.css";

function ItemGrilla({ ubicacion, fecha, descarga }) {
  const windowWidth = useRef(window.innerWidth);
  if (windowWidth.current >= 900) {
    return (
      <div className="wrapper-cards">
        <div className="img-card">
          <CajaGrilla ubicacion={ubicacion} />
        </div>
        <div className="marginTop">
          <p className="one-card">{fecha}</p>
          <a href={descarga} className="two-card" download>
            <DownloadButton />
          </a>
        </div>
      </div>
    );
  }

  if (windowWidth.current <= 900) {
    return (
      <div className="wrapper-cards">
        <div className="img-card">
          <CajaGrillaMobile ubicacion={ubicacion} />
        </div>
        <div className="marginTopMobile">
          <p className="one-card">{fecha}</p>
          <a href={descarga} className="two-card" download>
            <DownloadButton />
          </a>
        </div>
      </div>
    );
  }
}

ItemGrilla.propTypes = {
  ubicacion: PropTypes.string,
  fecha: PropTypes.string,
  descarga: PropTypes.string,
};

export default ItemGrilla;
