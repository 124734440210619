import React, { Component, useRef } from "react";
import { ReactComponent as DescargaAppDesktop } from "../../images/descarga-app-desktop.svg";
import { ReactComponent as DescargaAppMobile } from "../../images/descarga-app-mobile.svg";
import DescargaAppstore from "../../images/descarga-appstore.png";
import DescargaPlaystore from "../../images/descarga-googleplay.png";
import "./styles.css";

export default function DownloadApp() {
  const windowWidth = useRef(window.innerWidth);

  if (windowWidth.current >= 900) {
    return (
      <div>
        <DescargaAppDesktop />
      </div>
    );
  }
  if (windowWidth.current <= 900) {
    return (
      <div>
        <DescargaAppMobile />
      </div>
    );
  }
}
