import React, { useState } from "react";
import BotonDescarga from "../../images/boton-descarga.svg";
import "./styles.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "bootstrap/dist/css/bootstrap.css";

function DropdownMenu() {
  return (
    <div className="menuContainer item">
      <DropdownButton
        id="dropdown-basic-button"
        drop="down-centered"
        title=""
        className="descargaManifiesto"
      >
        <Dropdown.Item
          href="/downloads/manifiesto/NMEM23_MANIFIESTO.pdf"
          className="darkViolet"
          download
        >
          Descargar Manifiesto Manifiesto NMEM 2023 - MMGYD.pdf
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/accesible/Manifiesto - Ministerio de las Mujeres, Géneros y Diversidad.epub"
          className="lightViolet"
          download
        >
          Manifiesto NMEM 2023 - MMGYD (versión con imágenes).epub
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/accesible/Manifiesto - Ministerio de las Mujeres, Géneros y Diversidad.rtf"
          className="darkViolet"
          download
        >
          Manifiesto NMEM 2023 - MMGYD (versión sin imágenes).rtf
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/accesible/Manifiesto NMEM.mp3"
          className="lightViolet"
          download
        >
          Audio Manifiesto NMEM 2023 - MMGYD.mp3
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/lenguas-indigenas/NMEM23_MANIFIESTO Guarani.pdf"
          className="darkViolet"
          download
        >
          Manifiesto NMEM 2023 - MMGYD (versión en Guaraní).pdf
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/lenguas-indigenas/NMEM23_MANIFIESTO Mapuche.pdf"
          className="lightViolet"
          download
        >
          Manifiesto NMEM 2023 - MMGYD (versión en Mapuche).pdf
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/lenguas-indigenas/NMEM23_MANIFIESTO Quechua.pdf"
          className="darkViolet"
          download
        >
          Manifiesto NMEM 2023 - MMGYD (versión en Quechua).pdf
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/lenguas-indigenas/NMEM23_MANIFIESTO Qom.pdf"
          className="lightViolet"
          download
        >
          Manifiesto NMEM 2023 - MMGYD (versión en Qom).pdf
        </Dropdown.Item>
        <Dropdown.Item
          href="/downloads/manifiesto/lenguas-indigenas/NMEM23_MANIFIESTO Wichi.pdf"
          className="darkViolet"
          download
        >
          Manifiesto NMEM 2023 - MMGYD (versión en Wichi).pdf
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}

export default DropdownMenu;
