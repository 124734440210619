import React, { Component } from "react";
import { ReactComponent as BotonDescargaGrilla } from "../../images/boton-descarga-grilla.svg";
import "./styles.css";

export default class DownloadButton extends Component {
  render() {
    return (
      <div className="btn-download">
        <BotonDescargaGrilla />
      </div>
    );
  }
}
