import React, { Component } from "react";
import { ReactComponent as FondoHeader } from "../../images/header-completo.svg";
import { ReactComponent as FondoHeaderMobile } from "../../images/header-completo-mobile.svg";

class Header extends Component {
  render() {
    return (
      <div>
        <FondoHeader />
      </div>
    );
  }
}

class HeaderMobile extends Component {
  render() {
    return (
      <div>
        <FondoHeaderMobile />
      </div>
    );
  }
}

export { Header, HeaderMobile };
