import React, { Component } from "react";
import "./style.css";
import { ReactComponent as TextoMarzo } from "../../images/texto-marzo.svg";
import ItemGrilla from "../../components/ItemGrilla";

export default class Eventos extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="one">
          <ItemGrilla
            ubicacion="cck"
            fecha={"2 al 5 de Marzo"}
            descarga={"/downloads/grillas/NMEM23 - CCK.pdf"}
          />
        </div>
        <div className="two">
          <ItemGrilla
            ubicacion="tecnopolis"
            fecha={"3 al 5 de Marzo"}
            descarga={"/downloads/grillas/NMEM23 - Tecnopolis.pdf"}
          />
        </div>
        <div className="three">
          <ItemGrilla
            ubicacion="la rioja"
            fecha={"10 y 11 de Marzo"}
            descarga={"/downloads/grillas/NMEM23 - La Rioja.pdf"}
          />
        </div>
        <div className="four">
          <ItemGrilla
            ubicacion="la pampa"
            fecha={"10 de Marzo"}
            descarga={"/downloads/grillas/NMEM23 - La Pampa.pdf"}
          />
        </div>
        <div className="five">
          <ItemGrilla
            ubicacion="santiago del estero"
            fecha={"11 de Marzo"}
            descarga={"/downloads/grillas/NMEM23 - Santiago del Estero.pdf"}
          />
        </div>
        <div className="six">
          <ItemGrilla
            ubicacion="santa cruz"
            fecha={"11 y 12 de Marzo"}
            descarga={"/downloads/grillas/NMEM23 - Santa Cruz.pdf"}
          />
        </div>
        <div className="seven">
          <ItemGrilla
            ubicacion="salta"
            fecha={"29 de Marzo"}
            descarga={"/downloads/grillas/NMEM23 - Salta.pdf"}
          />
        </div>
      </div>
    );
  }
}
