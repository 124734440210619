import React, { Component, useRef } from "react";
import { ReactComponent as FondoManifiesto } from "../../images/fondo-manifiesto.svg";
import { ReactComponent as DescargaManifiestoMobile } from "../../images/descarga-manifiesto-mobile.svg";

import DropdownMenu from "../../components/DropdownMenu";
import "./styles.css";

export default function Manifiesto() {
  const windowWidth = useRef(window.innerWidth);

  if (windowWidth.current >= 900) {
    return (
      <div className="containerManifiesto">
        <DropdownMenu />
        <FondoManifiesto />
      </div>
    );
  }
  if (windowWidth.current <= 900) {
    return (
      <div className="containerManifiesto">
        <DropdownMenu />
        <DescargaManifiestoMobile />
      </div>
    );
  }
}
