import React, { useRef } from "react";
import { Header, HeaderMobile } from "../../components/Header";
import Footer from "../Footer";
import Eventos from "../Eventos";
import DownloadApp from "../DownloadApp";
import Manifiesto from "../Manifiesto";

const Home = () => {
  const windowWidth = useRef(window.innerWidth);
  if (windowWidth.current >= 900) {
    return (
      <div>
        <Header />
        <Eventos />
        <Manifiesto />
        <DownloadApp />
        <Footer />
      </div>
    );
  }
  if (windowWidth.current <= 900) {
    return (
      <div>
        <HeaderMobile />
        <Eventos />
        <Manifiesto />
        <DownloadApp />
        <Footer />
      </div>
    );
  }
};

export default Home;
