import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as CajaCCK } from "../../images/caja-cck.svg";
import { ReactComponent as CajaTecnopolis } from "../../images/caja-tecnopolis.svg";
import { ReactComponent as CajaSantiagoDelEstero } from "../../images/caja-santiagodelestero.svg";
import { ReactComponent as CajaSantaCruz } from "../../images/caja-santacruz.svg";
import { ReactComponent as CajaSalta } from "../../images/caja-salta.svg";
import { ReactComponent as CajaLaRioja } from "../../images/caja-larioja.svg";
import { ReactComponent as CajaLaPampa } from "../../images/caja-lapampa.svg";

import { ReactComponent as CajaCCKMobile } from "../../images/caja-cck-mobile.svg";
import { ReactComponent as CajaTecnopolisMobile } from "../../images/caja-tecnopolis-mobile.svg";
import { ReactComponent as CajaSantiagoDelEsteroMobile } from "../../images/caja-santiagodelestero-mobile.svg";
import { ReactComponent as CajaSantaCruzMobile } from "../../images/caja-santacruz-mobile.svg";
import { ReactComponent as CajaSaltaMobile } from "../../images/caja-salta-mobile.svg";
import { ReactComponent as CajaLaRiojaMobile } from "../../images/caja-larioja-mobile.svg";
import { ReactComponent as CajaLaPampaMobile } from "../../images/caja-lapampa-mobile.svg";

function CajaGrilla({ ubicacion }) {
  return (
    <div>
      {ubicacion === "cck" && <CajaCCK />}
      {ubicacion === "tecnopolis" && <CajaTecnopolis />}
      {ubicacion === "santiago del estero" && <CajaSantiagoDelEstero />}
      {ubicacion === "santa cruz" && <CajaSantaCruz />}
      {ubicacion === "salta" && <CajaSalta />}
      {ubicacion === "la rioja" && <CajaLaRioja />}
      {ubicacion === "la pampa" && <CajaLaPampa />}
    </div>
  );
}

CajaGrilla.propTypes = {
  ubicacion: PropTypes.string,
};

function CajaGrillaMobile({ ubicacion }) {
  return (
    <div>
      {ubicacion === "cck" && <CajaCCKMobile />}
      {ubicacion === "tecnopolis" && <CajaTecnopolisMobile />}
      {ubicacion === "santiago del estero" && <CajaSantiagoDelEsteroMobile />}
      {ubicacion === "santa cruz" && <CajaSantaCruzMobile />}
      {ubicacion === "salta" && <CajaSaltaMobile />}
      {ubicacion === "la rioja" && <CajaLaRiojaMobile />}
      {ubicacion === "la pampa" && <CajaLaPampaMobile />}
    </div>
  );
}

CajaGrilla.propTypes = {
  ubicacion: PropTypes.string,
};
CajaGrillaMobile.propTypes = {
  ubicacion: PropTypes.string,
};
export { CajaGrilla, CajaGrillaMobile };
